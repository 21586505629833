
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';
import { TablesToTables } from '@/components/data-operations/runs-collections';

@Component({
	components: { TablesToTablesCollectionListing: TablesToTables.collectionListing },
})
export default class TablesToTablesRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
